@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&family=Bebas+Neue&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.arrow{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.arrow span{
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid black;
    border-right: 5px solid black;
    transform: rotate(45deg);
    margin: -10px;
    -webkit-animation: animate 2s infinite;
            animation: animate 2s infinite;
}
.arrow span:nth-child(2){
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
}
.arrow span:nth-child(3){
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
}
@-webkit-keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}
@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}


/* font-family: 'Archivo Black', sans-serif;
font-family: 'Bebas Neue', cursive;
font-family: 'Raleway', sans-serif; */

html {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  /* background-color: #ececec; */
}
body {
  margin: 0
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

p{
  line-height: 29px;
}

h1 {
  font-family: 'Squada One', cursive;
  color:black;
  font-size: 3.3rem;
  margin-top: 0;
}

h2{
  font-size: 2.6rem;
  margin-top: 0;
}

a {
  text-decoration: none;
}

/* Intro Section */
.page1 {
  height: 300vh;
  width: 100%;
  background: black;
}

.nav {
  position: absolute;
  top: 2em;
  font-size: 1.2em;
  color: white;
  padding-bottom: 0.5em;
  border-bottom: white solid 1px;
  /* transition: 0.5 color, 0.5 border, 0.5 padding; */
}

.nav:hover {
  color: gainsboro;
  border-bottom: gainsboro;
  padding-bottom: 1em;
}

/* Headers Section */

.title-headers {
  width: 100%;
  color: white;
  font-family: 'Archivo Black', sans-serif;
  font-size: 90px;
  text-align: center;
}

/* About */
.about-section {
  background: #121212;
  width: 100%;
}

.about-container {
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10em;
}

.about-info {
  color: gray; 
  font-size: 2em; 
  line-height: 1.4em; 
  letter-spacing: 0.05em;
  width: 40%;
}

.about-pic {
  height: 60vh;
}

/* Project Section */

.project-container {
  margin-top: 5em;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #121212;
  color: gray
}

.picture-container {
  display: flex;
  justify-content: center;
}

.project-picture {
  width: 80vh;
  height: auto;
  border-radius: 5px 5px 5px 5px;
}

.photo-padding {
  padding-left: 4em;
}

.details-container {
  width: 35%;
  padding-left: 2em;
}
.project-title {
  margin-top: 5%;
  color: gainsboro;
}

.project-details {
  
}

.projects-title {
  justify-content: left;
}

.project-info {
  color: #c1c1c1;
  font-size: 1.2em;
  line-height: 2em;
}

.project-tools {
  font-family: 'Squada One', cursive;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  color: #c1c1c1;
}


/* Buttons */
.button-container {
  display: flex;
  height: 40%;
  align-items: center;
}

.github {
  background-color: gainsboro;
  letter-spacing: 0.15em;
  width: 100px;
  padding: 15px 12px; 
  border-radius: 10px;
  border: gray 2px solid;
  color: black;
  text-align: center;
  margin: 0px 15px 0 15px;
  transition: padding 0.5s,font-size 0.5s, font-weight 0.5s, border-radius 0.5s ;
  -webkit-transition: padding 0.5s,font-size 0.5s, font-weight 0.5s, border-radius 0.5s;
}

.github:hover {
  background: white;
  border: yellow 2px solid;
}

.about-title {
  color: white;
  font-size: 15em;
  font-family: 'Birds of Paradise';
}

.contact-form {
  color: gainsboro;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2em;
}

.input-container {
  display: flex;
  flex-direction: column;
  background-color: green;
}

.input-general {
  background-color: #131313;
  color: gainsboro;
  height: 2em;
  font-family: 'Lemonada', cursive;
  font-size: 0.75em;
  padding-top: 0.5em;
  margin-top: 2em;
  text-align: center;
  letter-spacing: 0.1em;
  
}

.input-small {
  border-bottom: gainsboro 1px solid;
  border-top: none;
  border-right: none;
  border-left: none;
  width: 40%;
  
}

.input-large {
  border: gainsboro 1px solid;
  margin-bottom: 1em;
  height: 9em;
  width: 50%
}

.input-submit {
  background-color: gainsboro;
  font-family: 'Roboto', sans-serif;
  font-size: 0.75em;
  letter-spacing: 0.15em;
  text-align: center;
  width: 10em;
  padding: 15px 12px; 
  border-radius: 10px;
}

/* Footer */

.footer {
  background: #121212; 
  text-align: center; 
  padding-top: 2.5em;
  padding-bottom: 3em;
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  background-color: grey
}

.footer-container {
  display: flex; 
  justify-content: center;
}

.footer-icon-container {
  padding-bottom: 1.5em;
  padding-right: 2em;
  padding-left: 2em;
  font-size: 1.2em;
  display: flex;
  color: inherit;
}

.footer-icon-container:hover {
  color: gainsboro;
}

.footer-icon {
  padding-right: 0.25em;
}

.footer-icon-name {

}
@media (max-width: 1400px){
  .about-info {
    width: 100%;
    padding: 0;
  }
  .about-pic {
    height: auto;
    max-width: 100%;
  }
}

@media (max-width: 1150px){
  .project-container {
    display: flex;
    flex-direction: column;
  }
  .title-headers {
    text-align: center;
  }
  .project-picture {
    max-width: 95%;
    max-height: 70vh;
    width: auto;
  }
  .details-container {
    text-align: center;
    width: 90%;
    padding: none;
  }
  .button-container {
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 0em;
  }
  .input-small {
    width: 60%;
    padding-right: 1em;
    padding-left: 1em;
  }
  .input-large {
    width: 70%;
    padding-right: 1em;
    padding-left: 1em;
  }
}



@media (max-width: 540px){
  html {
    font-size: 11px;
  }
  .title-headers {
    font-size: 60px;
  }
  .project-container{
    margin-top: 7em;
  }
  .project-picture {
    max-width: 95%;
  }
  .details-container {
    width: 90%;
  }
  .input-large {
    margin-bottom: 2em;
  }
  .footer {
    font-size: 10px;
  }
  .input-small {
    width: 70%;
    padding-right: 1em;
    padding-left: 1em;
  }
  .input-large {
    width: 80%;
    padding-right: 1em;
    padding-left: 1em;
  }
}

:root {
  --background: black;
  --dark: #363636;
}

.outerbox{
  position: fixed;
  display: flex; 
  justify-content: center; 
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  z-index: 100;
  background: black;
  -webkit-animation: fadeOut 4s ease-in-out 5s forwards;
          animation: fadeOut 4s ease-in-out 5s forwards;
}

.box {
  position: relative;
  background: black;
  /* border: 0.25rem solid var(--dark); */
  height: 26rem;
  width: 26rem;
  outline: 0;
  overflow: hidden;
  -webkit-animation: fadeOut 0.5s ease-in-out 4s forwards;
          animation: fadeOut 0.5s ease-in-out 4s forwards;
}

.box::before {
  content: url("/R_letter_birds_of_paradise.png");
  color: black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.box::after {
  content: " ";
  position: absolute;
  bottom: -50%;
  left: -50%;
  height: 200%;
  width: 200%;
  background: black;
  background: var(--background);
  border-radius: 45%;
  -webkit-animation: spin 6s ease-in-out forwards;
          animation: spin 6s ease-in-out forwards;
}

@-webkit-keyframes spin {
  0% {
      transform: translateY(-20%) rotate(0deg);
  }

  100% {
      transform: translateY(-100%) rotate(500deg);
  }
  
}

@keyframes spin {
  0% {
      transform: translateY(-20%) rotate(0deg);
  }

  100% {
      transform: translateY(-100%) rotate(500deg);
  }
  
}

@-webkit-keyframes fadeOut {
  0% { opacity: 1;}
  99% { opacity: 0.01;width: 100%; height: 100%;}
  100% { opacity: 0;width: 0; height: 0;}
} 

@keyframes fadeOut {
  0% { opacity: 1;}
  99% { opacity: 0.01;width: 100%; height: 100%;}
  100% { opacity: 0;width: 0; height: 0;}
} 


